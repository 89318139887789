import React, { useState, useEffect, useContext } from "react";
import { Outlet, useParams, useNavigate, Navigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import accountsService from "../services/accounts-service";
import {
  Box,
  Container,
  Toolbar,
  Button,
  Typography,
  Menu,
  MenuItem,
  AppBar,
  Stack,
  Grid,
  Link,
  Avatar,
} from "@mui/material";
import Login from "./Login";
import usersService from "../services/users-service";
import authService from "../services/auth-service";
import createManifest from "../services/pwa-manifest-helper";

import { useAuth } from "../context/AuthContext";

import LanguageIcon from "@mui/icons-material/Language";

import { useTranslation } from "react-i18next";

const lngs = {
  se: { nativeName: "Svenska" },
  en: { nativeName: "English" },
};

export default function Main() {
  const { t, i18n } = useTranslation();
  const { account } = useParams();
  const { token, setToken, user, setUser } = useAuth();
  const { accountData, setAccountData } = useAuth();
  const navigate = useNavigate();

  const [menu, setMenu] = useState(null);
  const [userMenu, setUserMenu] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleNavigate = (path) => {
    navigate(path);
    setMenu(null);
  };

  const logOut = () => {
    authService.logout();
    setToken(null);
    setUserMenu(null);
    navigate(`/${account}/Login`);
  };

  const verifyUser = () => {
    try {
      const user = authService.getUser();
      if (user === null) throw "No user found";
      if (user.account !== account) throw "Accounts do not match!";
      setToken(authService.getToken());
      setUser(user);
      return user;
    } catch (error) {
      console.log("verifyUser failed", error);
      authService.logout();
      navigate(`/${account}/Login`);
    }
  };

  const getCurrentUserRole = async (currentUser) => {
    if (currentUser) {
      usersService
        .getUserRoles(currentUser.userId)
        .then((res) => {
          const admin = res.find((x) => x.role.toLowerCase() === "admin");
          if (admin) {
            setIsAdmin(true);
          }
        })
        .catch((error) => console.log(`Failed to get roles`, error));
    }
  };

  const getAccount = async () => {
    console.log("getAccount", account);
    return await accountsService.getAccount(account);
  };

  useEffect(() => {
    (async () => {
      const currentUser = verifyUser();
      let a = null;
      try {
        a = await getAccount();
        setAccountData(a);
      } catch (error) {
        console.log(`Failed to get account '${account}'`, error);
        console.log("account not found/active. Route to bockat.se...");
        navigate("/");
        return;
      }

      try {
        await getCurrentUserRole(currentUser);
        createManifest(a);
      } catch (error) {
        console.log("Failed to get user...");
        authService.logout();
        navigate(`/${account}`);
      }
    })();
  }, []);
  return (
    <>
      {accountData && accountData.settings && (
        <div>
          <AppBar
            position="static"
            sx={{
              background: accountData.settings.headerColor,
              height: "65px",
            }}
          >
            <Toolbar>
              <Box display="flex" flexGrow={1} alignItems="center">
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-controls="menu"
                  aria-haspopup="true"
                  onClick={(e) => setMenu(e.currentTarget)}
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu"
                  anchorEl={menu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(menu)}
                  onClose={() => setMenu(null)}
                >
                  <MenuItem onClick={() => handleNavigate(`/${account}`)}>
                    {t("main.home")}
                  </MenuItem>
                  {isAdmin && (
                    <div>
                      <MenuItem onClick={() => handleNavigate("users")}>
                        {t("main.users")}
                      </MenuItem>
                      <MenuItem onClick={() => handleNavigate("projects")}>
                        {t("main.projects")}
                      </MenuItem>
                      <MenuItem onClick={() => handleNavigate("suppliers")}>
                        {t("main.suppliers")}
                      </MenuItem>
                      <MenuItem onClick={() => handleNavigate("stands")}>
                        {t("main.stands")}
                      </MenuItem>
                    </div>
                  )}
                </Menu>
                <Typography variant="h5" noWrap pt={1}>
                  <img
                    style={{
                      width: "auto",
                      height: "40px",
                      cursor: "pointer",
                    }}
                    alt="logo"
                    src={accountData.settings.logo}
                    onClick={() => navigate(`/${account}`)}
                  />
                </Typography>
                {accountData.settings.logoTitle && (
                  <Typography variant="h5" pl={2}>
                    {accountData.settings.logoTitle}
                  </Typography>
                )}
              </Box>

              <IconButton
                edge="end"
                size="large"
                aria-label="account of current user"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={(e) => setUserMenu(e.currentTarget)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              {token && (
                <Menu
                  id="user-menu"
                  anchorEl={userMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(userMenu)}
                  onClose={() => setUserMenu(null)}
                >
                  {user && (
                    <MenuItem onClick={() => navigate("settings")}>
                      <Avatar
                        alt={user.name}
                        src="/blank"
                        sx={{ width: 28, height: 28 }}
                      />
                      &nbsp;&nbsp;{user.name}
                    </MenuItem>
                  )}

                  <MenuItem onClick={logOut}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    {t("main.logout")}
                  </MenuItem>
                  {Object.keys(lngs).map((lng) => (
                    <MenuItem key={lng}>
                      <ListItemIcon>
                        <LanguageIcon fontSize="small" />
                      </ListItemIcon>
                      <Button
                        size="small"
                        key={lng}
                        style={{
                          fontWeight:
                            i18n.resolvedLanguage === lng ? "bold" : "normal",
                        }}
                        type="submit"
                        onClick={() => i18n.changeLanguage(lng)}
                      >
                        {lngs[lng].nativeName}
                      </Button>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Toolbar>
          </AppBar>
          {token && <Outlet />}
        </div>
      )}
    </>
  );
}
